/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../../components/Layout";
import ProductRoll from "../../components/ProductRoll";

const ProductIndexPage = () => {
  return (
    <Layout>
      <ProductRoll />
    </Layout>
  );
};

export default ProductIndexPage;
