/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import FeaturedProductsCarousel from "./FeaturedProductsCarousel";

const ProductRoll = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ProductQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "product-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                price
                outofstock
                imagegallery {
                  productcolor
                  hexcode
                  alttext
                  image {
                    childImageSharp {
                      fluid(maxWidth: 900, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const { edges } = allMarkdownRemark;

  return (
    <div
      sx={{
        maxWidth: "1600px",
        mb: [5, 6],
        pt: ["70px"],
        pb: 4,
        mr: "auto",
        ml: "auto",
      }}
    >
      <div sx={{ mx: [4, 5] }}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "repeat(2, 1fr)",
              "repeat(6, 1fr)",
              "repeat(8, 1fr)",
            ],
            gridGap: [4, null, 5],
          }}
        >
          {edges.map(({ node }) => {
            return (
              <div
                key={node.id}
                sx={{
                  gridColumn: ["1 / span 2", "span 2"],
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {node.frontmatter.imagegallery.length > 0 && (
                  <FeaturedProductsCarousel product={node} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductRoll;

ProductRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};
